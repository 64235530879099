import React from 'react'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import './insights.css'
import marked from 'marked'
class InsightsIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = `Insights`
    const features = data.featuredInsights.edges
    const posts = data.allInsights.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        features={features}
      >
        <SEO
          title={siteTitle}
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <section className="article-list py-5">
          <Container>
            <h3 className="text-blue mb-5">All stories in Insights</h3>
            <Row>
              {posts.map(({ node }) => {
                return (
                  <Col sm={6} md={4} lg={3} key={node.id}>
                    <LazyLoadComponent>
                      <div
                        className="article-list-banner mb-3 mb-sm-2"
                        style={{
                          backgroundImage: `url(${node.mainImage &&
                            node.mainImage.asset &&
                            node.mainImage.asset &&
                            node.mainImage.asset.fluid &&
                            node.mainImage.asset.fluid.src})`,
                          backgroundPosition: `center center`,
                          backgroundSize: `cover`,
                          height: `180px`,
                        }}
                      />

                      <h6>
                        <Link
                          className="article-list-link"
                          style={{ boxShadow: `none` }}
                          to={`/insights/${node.slug.current}`}
                          title={node.title}
                        >
                          {node.title}
                        </Link>
                      </h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marked(node.excerpt),
                        }}
                      />
                      <div class="article-list-info">
                        <ul class="list-unstyled d-flex">
                          {node.authors.length !== 0 && (
                            <li className="mr-3">
                              {node.authors[0].person.name}
                            </li>
                          )}
                          <li>
                            {node.publishedAt
                              ? node.publishedAt
                              : node._createdAt}
                          </li>
                        </ul>
                      </div>
                    </LazyLoadComponent>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default InsightsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    featuredInsights: allSanityInsight(
      sort: { fields: publishedAt, order: DESC }
      filter: { featuredInsight: { eq: true } }
      limit: 4
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          featuredInsight
          body
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid(maxWidth: 300) {
                src
              }
            }
          }
          excerpt
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
    allInsights: allSanityInsight(
      filter: { featuredInsight: { ne: true } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          featuredInsight
          body
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid(maxWidth: 300) {
                src
              }
            }
          }
          excerpt
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
